<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.configuration_contrats') }}</h3>

				<div class="row">
					<div class="col-12">
						<div class="form-group" v-if="hideSeasonNumber">
							<label class="col-form-label">{{ $t("monte.numero_contrat") }}</label>
							<div class="form-inline row">
								<b-input-group class="col-5">
									<b-input-group-prepend id="popover-target-format-info">
										<span class="input-group-text">
											<font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
										</span>
									</b-input-group-prepend>
									<input
										class="form-control col-2"
										v-model="form.contractconfigglobal_templatepattern"
										required
									/>
									<b-form-input
										v-model="form.contractconfigglobal_templatenumber"
										required
										:class="{ 'is-invalid': errors && errors.indexOf('templatenumber') > -1 }"

									></b-form-input>
									<b-popover target="popover-target-format-info" triggers="hover" placement="top">
										<template v-slot:title>{{ $t('monte.format_numero_contrat') }}</template>
										<p
											v-for="(val, key) in date_formatter"
											:key="key"
										>
											<b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
										</p>
										
									</b-popover>
								</b-input-group>
								<span disabled class="form-control col-5">
									{{ formatted_contract_num }}
								</span>
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">{{ $t("monte.a_partir_de") }}</label>
							<input
								type="number"
								class="form-control"
								v-model="form.contractconfigglobal_fromnumber"
								:class="{ 'is-invalid': errors && errors.indexOf('fromnumber') > -1 }"
								required
							>
						</div>

						<div class="form-group">
							<label class="col-form-label">{{ $t("monte.nombre_paillettes") }}</label>
							<input
								type="number"
								class="form-control"
								v-model="form.contractconfigglobal_paillette"
								required
							>
						</div>

						<div class="form-group">
							<label for="contractconfigglobal_configuration">{{ $t('monte.rib') }}</label>
							<e-select
								v-model="form.contractconfigglobal_configuration"
								track-by="checkdepositconfiguration_id"
								label="checkdepositconfiguration_iban"
								:placeholder="labelTitleRib"
								:selectedLabel="selectedLabelRib"
								:options="rib_options"
								:searchable="true"
								:allow-empty="true"
								:show-labels="false"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
								<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>

						 <div class="form-group">
							<label for="contractconfig_default_cmep">{{ $t('monte.cmep') }}</label>
							<SearchTiers 
								:tiers_selected.sync="form.cmep"
								:preselected="form.cmep"
							/>
						</div>

						<div class="form-group text-center">
							<button class="btn btn-primary rounded-pill" @click='checkForm'>{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" /></button>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="row">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.contract_config_lisaison_etalons') }}</h3>

				<CustomTable
					id_table="contract_config_stallions"
					ref="table_conditions"
					:items="horses_contract_config"
					:busy="table_busy"
					:hasExportOption="false"
					:externSlotColumns="extern_slot_columns"
					primaryKey="contractconfig_id"
					:hrefsRoutes="config_table_hrefs"
				>
					<template v-slot:[`custom-slot-cell(number_templates.numbertemplate_prefix)`]="{ data }">
						<input v-if="hideSeasonNumber" type="text" v-model="data.number_templates.numbertemplate_prefix" class="form-control" 
							@change="setElemToEdit('number_templates.numbertemplate_prefix', data.number_templates.numbertemplate_prefix, data.contractconfig_id)" 
						/>

					</template>
					<template v-slot:[`custom-slot-cell(number_templates.numbertemplate_pattern)`]="{ data }">
						<input v-if="hideSeasonNumber" type="text" v-model="data.number_templates.numbertemplate_pattern" class="form-control" 
							@change="setElemToEdit('number_templates.numbertemplate_pattern', data.number_templates.numbertemplate_pattern, data.contractconfig_id)" 
						/>
					</template>
					<template v-if="hideSeasonNumber" v-slot:[`custom-slot-cell(contractconfig_fromnumber)`]="{ data }">
						<input type="text" v-model="data.contractconfig_fromnumber" class="form-control"
							@change="setElemToEdit('contractconfig_fromnumber', data.contractconfig_fromnumber, data.contractconfig_id)" 
						/>
					</template>
					<template v-slot:[`custom-slot-cell(contractconfig_paillette)`]="{ data }">
						<input type="text" v-model="data.contractconfig_paillette" class="form-control"
							@change="setElemToEdit('contractconfig_paillette', data.contractconfig_paillette, data.contractconfig_id)" 
						/>
					</template>
					<template v-slot:[`custom-slot-cell(contractconfig_configuration)`]="{ data }">
						<e-select
							v-model="data.contractconfig_configuration"
							track-by="checkdepositconfiguration_id"
							label="checkdepositconfiguration_iban"
							:placeholder="labelTitleRib"
							:selectedLabel="selectedLabelRib"
							:options="rib_options"
							:searchable="true"
							:allow-empty="true"
							:show-labels="false"
							@input="changeConfig(data.contractconfig_configuration, data.contractconfig_id)"
							@open="onTableSelectOpen"
							@close="onTableSelectClose"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
							<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</template>
				</CustomTable>
			</div>
			<div class="col-6 col-lg-2 m-auto">
				<b-button block pill variant="primary" v-if="!table_busy" @click="save_contract_stallions"><font-awesome-icon v-if="processing_stallions" :icon="['fas', 'spinner']" pulse /> {{ $t("global.sauvegarder") }}</b-button>
			</div>
		</div>


		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.add_stallion_to_conditions") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="submitForm" class="mb-4">
					<div class="row">

						<div class="col-12">
							<div class="form-group">
								<label for="horse_id">{{ $t('horse.horse') }}</label>
								<e-select
									v-model="horse"
									track-by="horse_id"
									label="horse_nom"
									:placeholder="labelTitleHorse"
									:selectedLabel="selectedLabel"
									:options="horses"
									:searchable="true"
									:multiple="true"
									:allow-empty="false"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('horse') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button block pill variant="primary" @click="submitForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import TableSelectMixin from "@/mixins/TableSelect.js"
	import _set from 'lodash/set'
	import { EventBus } from 'EventBus'

	export default {
		name: "SaisonContractConfigGlobal",
		props: ['season_id'],
		mixins: [Navigation, ContractMixin, TableSelectMixin],
		data () {
			return {
				events_tab: {
					"TableAction::goToAddStallionToContractConfigGlobal": this.openModalContractConfig,
					"TableAction::goToDeleteStallionToContractConfigGlobal": (contractconfig_ids) => { 
						this.deleteContractConfig(contractconfig_ids)
					}
				},
				form: {
					contractconfigglobal_fromnumber: '0',
					contractconfigglobal_templatenumber: '999',
					contractconfigglobal_templatepattern: 'CCC',
					contractconfigglobal_paillette: 0,
					contractconfigglobal_configuration: null,
					cmep: {},
				},
				modal_loaded: false,
				local_season_id: null,
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					}
				},
				horses_contract_config: [],
				horses: [],
				horse: null,
				errors: [],
				processing: false,
				processing_stallions: false,
				table_busy: false,
				selectedLabel: this.getTrad("global.selected_label"),
				labelTitleHorse: this.getTrad("horse.rechercher_cheval"),
				labelTitleRib: this.getTrad("monte.rechercher_rib"),
				selectedLabelRib: this.getTrad("monte.rechercher_rib"),
				rib_options: [],
				date_formatter: {
                    '9': {
                        label: this.$t('compta.form.format_9'),
                        value: 0
                    },
                    'C': {
                        label: this.$t('compta.form.format_C'),
                        value: 'C'
                    },
                },
				extern_slot_columns: [
					'number_templates.numbertemplate_prefix',
					'number_templates.numbertemplate_pattern',
					'contractconfig_fromnumber',
					'contractconfig_paillette',
					'contractconfig_configuration'
				]
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {

				this.table_busy = true
				this.local_season_id = this.$sync.replaceWithReplicated('season', this.season_id)
				let form = await this.loadConditionsSeasonContractConfig(this.local_season_id)
				if(form != null) {
					this.form = form
					let horses_contract_config = await this.getStallionContractConfigFromGlobal(this.form.contractconfigglobal_id)
					if(horses_contract_config.contract_config) {
						this.horses_contract_config = horses_contract_config.contract_config
					}
				}
				else{
					this.checkForm()
				}

				let all_ribs = await this.getAllCheckDepositConfig()
				this.rib_options = []

				for (let index = 0; index < all_ribs.length; index++) {
					const element = all_ribs[index];

					this.rib_options.push(element)
					if(this.form.contractconfigglobal_configuration && (this.form.contractconfigglobal_configuration == element.checkdepositconfiguration_id || (this.form.contractconfigglobal_configuration != null && this.form.contractconfigglobal_configuration.checkdepositconfiguration_id == element.checkdepositconfiguration_id))){
						this.form.contractconfigglobal_configuration = element
					}
				}

				for (let index = 0; index < this.horses_contract_config.length; index++) {
					const element = this.horses_contract_config[index];

					for (let i = 0; i < all_ribs.length; i++) {
						const element_rib = all_ribs[i];
						if(element.contractconfig_configuration && (element.contractconfig_configuration == element_rib.checkdepositconfiguration_id || (element.contractconfig_configuration != null && element.contractconfig_configuration.checkdepositconfiguration_id == element_rib.checkdepositconfiguration_id))){
							element.contractconfig_configuration = element_rib
							break;
						}
					}
					this.$set(this.horses_contract_config[index], element)
				}

				if(Array.isArray(this.horses_contract_config.cmep) === false)
				{
					this.form.cmep = this.horses_contract_config.cmep
				}
				

				this.table_busy = false
			},

			async checkForm(){
				
				if(this.processing == false)
				{
					this.processing = true
					this.errors = []

					if(this.form.contractconfigglobal_templatenumber === "") {
						this.errors.push("templatenumber")
					}

					if(this.form.contractconfigglobal_fromnumber === "") {
						this.errors.push("fromnumber")
					}

					if(this.errors.length > 0) {
						this.processing = false
						return false
					}

					let result = await this.saveContractConfigGlobal(this.local_season_id, this.form)
					if(result != null){
						this.successToast()
						this.init_component()
					}

					this.processing = false
				}
			},
			async setElemToEdit(elem, value, contractconfig_id){
				const elem_to_edit = this.horses_contract_config.find(elem => contractconfig_id == elem.contractconfig_id)
				_set(elem_to_edit, elem, value);
			},

			async submitForm(){
				if(this.horse.length > 0)
				{
					let result = await this.createContractConfigFromGlobal(this.horse, this.form.contractconfigglobal_id)
					if(result != null) {
						this.init_component()
						this.successToast()
						this.$refs.modal.hide()
					}
					else{
						this.failureToast()
					}
				}
			},

			async openModalContractConfig(){

				this.$refs.modal.show()

				this.horses = await this.loadHorsesNotInContractConfig(this.form.contractconfigglobal_id)
				this.modal_loaded = true

			},

			async save_contract_stallions(){
				if(this.processing_stallions == false)
				{
					this.processing_stallions = true
					this.errors = []


					if(this.errors.length > 0) {
						this.processing_stallions = false
						return false
					}
					let tab = []
					for (let index = 0; index < this.horses_contract_config.length; index++) {
						const element = this.horses_contract_config[index];
						tab.push({
							contractconfig_id: element.contractconfig_id,
							contractconfig_paillette: element.contractconfig_paillette,
							contractconfig_fromnumber: element.contractconfig_fromnumber,
							contractconfig_configuration: (element.contractconfig_configuration != null) ? element.contractconfig_configuration.checkdepositconfiguration_id: null,
							number_templates: {
								numbertemplate_prefix: element.number_templates.numbertemplate_prefix,
								numbertemplate_pattern: element.number_templates.numbertemplate_pattern,
							}
						})
					}

					let result = await this.saveContractConfigStallionNumberTemplate(tab)
					if(result != null){
						this.successToast()
						this.init_component()
					}

					this.processing_stallions = false
				}
			},

			changeConfig(value, id){
				const elem_to_edit = this.horses_contract_config.find(elem => id == elem.contractconfig_id)
				elem_to_edit.contractconfig_configuration = value
			},
			async deleteContractConfig(contractconfig_ids) {
				this.deleteContractConfigFromGlobal(this.form.contractconfigglobal_id, contractconfig_ids)
					.then((res) => {
						if(!res) {
							this.failureToast('toast.has_contracts')
						}

						EventBus.$emit('TableAction::stopSpin')
						this.unselectAll()
						this.init_component()
					})
			},
			unselectAll() {
        		this.$refs.table_conditions.unselectAll()
        	}
		},
		computed: {
			formatted_contract_num () {
			    // let num = this.form.contractconfigglobal_templatenumber

			    // Object.keys(this.date_formatter).forEach(identifier => {
			    //     num = num.replace(new RegExp(identifier, 'g'), this.date_formatter[identifier].value)
			    // })

			    // return num
				let num = this.form.contractconfigglobal_templatenumber

			    Object.keys(this.date_formatter).forEach(identifier => {
			        num = num.replace(new RegExp(identifier, 'g'), this.date_formatter[identifier].value)
			    })

			    return this.form.contractconfigglobal_templatepattern + num
			},

			hideSeasonNumber() {
				return this.$attrs.season && this.$attrs.season.number_template && this.$attrs.season.number_template.length == 0
			}
			
		},
		watch: {
			
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			DecimalInput: () => import('GroomyRoot/components/Inputs/DecimalInput')
		}
	}
</script>
